<template>
  <v-dialog v-model="dialog" persistent max-width="430px">
    <v-card class="custom-service-modal">
      <v-card-title>
        <v-btn
          fab
          dark
          color="info"
          x-small
          text
          @click="$emit('closeEditModal')"
        >
          <IconPictoAarrowsLeft />
        </v-btn>
        <span class="custom-service-modal__title">Éditer la prestation</span>
        <v-btn
          fab
          dark
          color="info"
          x-small
          text
          @click="$emit('closeEditModal')"
        >
          <v-icon dark large> mdi-window-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="custom-service-modal__body">
          <v-form
            class="form"
            @submit.prevent="submit"
            v-model="valid"
            ref="form-custom-service"
          >
            <div class="form__body">
              <v-text-field
                v-model="formData.name"
                outlined
                label="Nom de la prestation*"
                type="text"
                persistent-placeholder
                dense
                placeholder="Installation  veranda"
                :rules="[validations.required]"
              ></v-text-field>
              <v-text-field
                v-if="$store.getters['authModule/isSuperUser']"
                v-model.number="formData.default_price"
                outlined
                label="Prix"
                type="text"
                persistent-placeholder
                dense
                placeholder="Prix"
              ></v-text-field>
              <v-textarea
                v-model="formData.description"
                outlined
                label="Description"
                type="text"
                persistent-placeholder
                dense
                placeholder="Faîtes une description de la prestation"
              ></v-textarea>
              <div class="text-center">
                <v-btn
                  color="info"
                  depressed
                  type="submit"
                  class="form__submit"
                  :loading="loading"
                >
                  Éditer LA PRESTATION
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import validations from "@/validations/index";
import {
  CustomRoomServicesService,
  RoomServicesService,
} from "@/services/roomServices.sevice.js";

export default {
  name: "AddCustomServiceModal",
  props: {
    show: {
      type: Boolean,
    },
    roomId: {
      type: Number,
      reaquired: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconPictoAarrowsLeft: () => import("~cp/Icons/IconPictoAarrowsLeft"),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      formData: {},
      valid: true,
    };
  },
  computed: {
    validations() {
      return validations;
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.dialog = newVal;
      },
    },
    model: {
      immediate: true,
      handler(newVal) {
        this.formData = Object.assign(
          {},
          {
            name: newVal?.name,
            description: newVal?.description,
            category_id: newVal?.roomService?.category_id,
            quota_room_id: newVal?.roomService?.quota_room_id,
            unit_id: newVal?.unit_id,
            default_price: newVal?.default_price,
          }
        );
      },
    },
  },
  methods: {
    submit() {
      this.$refs["form-custom-service"].validate();
      if (!this.valid) return;
      this.loading = true;
      CustomRoomServicesService.updateService(this.model.id, this.formData)
        .then(({ data }) => {
          this.$emit("closeEditModal");
          this.$emit("updateCustomService", this.model.id);
        })
        .catch(() => {
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style src="./CustomServiceModal.scss" lang="scss" scoped></style>